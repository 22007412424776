import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from "react-bootstrap"
import Header from "../components/header"
import Seo from "../components/seo"
import fam from "../images/family.jpeg"
import bubblestwo from "../images/speechbubbles2.png"
import bubblesthree from "../images/speechbubbles3.png"

const LanguagePage = () => (
  <>
  <div>
    <Seo title="Home" />
    <Header />
    <Row style={{margin: `0`, padding:'5px 1em', backgroundColor: '#98c5ef'}}>
      <Col xs={6}>
        <p style={{ textAlign:'left', margin: '0', color:`#01264f`, fontFamily:`Avenir Next Condensed`, fontWeight:`500`, fontSize:`14px`}}>English Accent Training | Speech+Language Therapy | Reading+Literacy Intervention</p>
      </Col> 
      <Col xs={6} style={{textAlign:'right'}}>
        <a href="tel:+2252786015" style={{color:`#01264f`, fontFamily:`Rockwell`, fontWeight:`800`, fontSize:`18px`, textDecoration:'none'}}>225.278.6015</a>
      </Col>
   </Row>
    <Container fluid>
      <Row style={{marginTop: `20px`}}>
        <Col sm={3} style={{margin: `0`, padding: `0`}}>  
        <img src={bubblestwo} alt="Singh Speech, Language and Reading Intervention" style={{height:'auto',width:'100%'}} />  
       </Col>
        <Col sm={6}> 
        <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', fontSize:'36px', fontStyle:'900', margin:'1.5em 0'}}>One-on-One Online + Hybrid/In-Person Speech, Language and Reading Intervention Services for Children and Adults</p>
        </Col>
        <Col sm={3}>
        <img src={bubblesthree} alt="Singh Speech, Language and Reading Intervention" style={{height:'auto',width:'100%', marginTop:'15px'}} />  
        </Col>
      </Row>
      <Row>
        <Col sm={6} style={{background: `#e6e0d7`}}>
          <h1 style={{ margin: '40px 20px', color:`#012b4f`, textAlign:'center', fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`28px` }}>
            Language
          </h1>
          <p style={{textAlign:'left', fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'900', margin:'10px 20px'}}>Language expression and comprehension build the foundation for developing reading, spelling, and writing later in childhood.  Research showns that many children with learning impairments like dyslexia also have impaired expressive and receptive language skills.</p> 
          <p style={{textAlign:'left', fontFamily:'Avenir Next Condensed', fontSize:'20px', fontStyle:'900', margin:'10px 20px'}}>This is why every client's expressive (verbal) and receptive (comprehension) language skills are thoroughly evaluated in addition to reading and writing.</p> 
        </Col>
        <Col sm={6} style={{margin: `0`, padding: `0`}}>
          <img src={fam} alt="Singh Speech, Language and Reading Intervention" style={{margin: `0`, padding: `0`}} />
        </Col>
      </Row>
      <Row>
      <Col sm={6} style={{margin: `0`, padding: `0`}}>
        <img src={fam} alt="Singh Speech, Language and Reading Intervention" style={{margin: `0`, padding: `0`}} />
        </Col>
        <Col sm={6} style={{background: `#012b4f`}}>
        <h1 style={{ margin: '40px 20px', color:`#98c5ef`, textAlign:'center', fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`36px` }}>
            Reading
          </h1>
          <p style={{textAlign:'left', color:'#fff', fontFamily:'Avenir Next Condensed', fontSize:'28px', fontStyle:'900', margin:'10px'}}>Expressive/Rec</p>
          <p style={{textAlign:'left', color:'#fff', fontFamily:'Avenir Next Condensed', fontSize:'28px', fontStyle:'900', margin:'10px'}}>Expressive/Rec</p>
        </Col>
      </Row>
      <Row>
        <Col xs={6} style={{background: `#e6e0d7`}}>
        <h1 style={{ margin: '40px 20px', color:`#26bd0f`, textAlign:'center', fontFamily:`Rockwell`, fontWeight:`600`, fontSize:`36px` }}>
            Speech
          </h1>
          <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', fontSize:'24px', fontStyle:'900', margin:'10px'}}>Expressive/Rec</p>
        </Col>
        <Col xs={6} style={{margin: `0`, padding: `0`}}>
        <img src={fam} alt="Singh Speech, Language and Reading Intervention" style={{margin: `0`, padding: `0`}} />
        
        </Col>
      </Row>
      <Row>
      <Col>  <p style={{textAlign:'center', fontFamily:'Avenir Next Condensed', fontSize:'36px', margin:'1.25em 4em'}}>Ready to schedule?</p>

<div style={{textAlign:'center', width:'200px',border:'2px solid #26bd0f',fontFamily:'Avenir Next Condensed', fontSize:'24px', padding:'1em', margin:'0 auto'}}>
  <a href="https://leah-singh.clientsecure.me" data-spwidget-scope-id="9499cadb-6b09-4b98-aca6-1512b5f8b55d" data-spwidget-scope-uri="leah-singh" data-spwidget-application-id="7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b" data-spwidget-scope-global data-spwidget-autobind>Request Appointment</a>
</div>
        </Col>
      </Row>
    </Container> 
    <footer
      style={{
      marginTop: `2rem`,
      textAlign: `center`
    }}
  >
  © {new Date().getFullYear()}
  {` `}
  <a href="https://www.lsdigitaltech.com">LS Digital Tech, LLC.</a>
</footer>
</div>
</>
)

export default LanguagePage
